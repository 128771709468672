<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-card class="mb-0" id="unityauth-card">
                <b-link class="brand-logo">
                    <gameguru-logo />
                    <h2 class="brand-text text-primary ml-1">
                        Gameguru
                    </h2>
                </b-link>
                <div class="text-center">
                    <div class="loading mt-2">
                        <div class="effect-1 effects"></div>
                        <div class="effect-2 effects"></div>
                        <div class="effect-3 effects"></div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
    import axios from '@axios'
    import GameguruLogo from '@/components/GameguruLogo.vue'
    import {
        BCard,
        BLink,
        BCardText,
        BCardTitle
    } from 'bootstrap-vue'
    export default {
        components: {
            GameguruLogo,
            BCard,
            BLink,
            BCardText,
            BCardTitle,
        },
        mounted() {
            this.redirectToken()
        },

        methods: {
            redirectToken() {
                return new Promise((resolve, reject) => {
                    axios
                        .post(`/auth/create-token?type=unity`)
                        .then(response => {
                            const {
                                data
                            } = response
                            window.location.href = process.env.VUE_APP_UNITY_TOKEN_URL + 'unity?token=' + data.data
                        })
                        .catch(error => reject(error))
                })
            }
        }
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    #unityauth-card .card-body{
        padding-bottom: 0px!important;
    }
</style>